<template>
  <div
    class="demo-inline-spacing-50 mt-25 pt-25"
    style="border-top: 1px dashed #bababa"
  >
    <!-- ANCHOR - Số chỗ -->
    <div
      v-if="trip.source !== '1A'"
      class="d-flex-center"
    >
      <b-img
        class="mr-25"
        blank-color="#ccc"
        style="height: 24px;"
        :src="require(`@/assets/other-icon/seat.svg`)"
      />
      <span class="text-nowrap">
        {{ $t('flight.seatAvailable') }}:
        <b class="text-primary font-weight-bold">
          {{ fareItem.seatAvailable }}
        </b>
      </span>
    </div>

    <!-- SECTION - Điều kiện hành lý -->
    <div
      v-if="!['F1'].includes(trip.source)"
      class="d-flex-center"
    >
      <!-- NOTE: international -->
      <div
        v-if="!trip?.domestic"
        class="text-truncate d-flex-center"
      >
        <!-- ANCHOR F1: đang ẩn -->
        <b
          v-if="['F1'].includes(trip.source)"
          :id="`baggage-${trip.id}-${fareItem.id}`"
          class="text-dark font-weight-bold"
        >
          <LuggageIcon :no-luggage="!(fareItem.bagWeight && fareItem.bagWeightUnit)" />
          <span v-if="fareItem.bagWeight && fareItem.bagWeightUnit">
            {{ fareItem.bagWeight }} {{ fareItem.bagWeightUnit }}
          </span>
          <span v-else>
            {{ $t('flight.bagNotInclude') }}
          </span>
          <b-tooltip
            v-if="fareItem.bagWeight && fareItem.bagWeightUnit"
            triggers="hover"
            variant="info"
            placement="top"
            boundary="window"
            :target="`baggage-${trip.id}-${fareItem.id}`"
          >
            <div class="text-heading-5 text-white">
              {{ fareItem.bagWeight }} {{ fareItem.bagWeightUnit }}
            </div>
          </b-tooltip>
        </b>

        <!-- ANCHOR: 1G, AK, TH, 1A, 1B -->
        <b
          v-if="['1G', 'AK', 'TH', '1A', '1B'].includes(trip.source)"
          :id="`baggage-${trip.id}-${fareItem.id}`"
          class="text-dark font-weight-bold"
        >
          <LuggageIcon :no-luggage="!(fareItem.baggageAllowances && resolveBaggage1GAKComputed.checkinBag)" />
          <span v-if="fareItem.baggageAllowances && resolveBaggage1GAKComputed.text">
            {{ resolveBaggage1GAKComputed.text }}
          </span>
          <span v-else>
            {{ $t('flight.bagNotInclude') }}
          </span>
          <b-tooltip
            v-if="fareItem.baggageAllowances && resolveBaggage1GAKComputed.text"
            triggers="hover"
            variant="info"
            placement="top"
            boundary="window"
            :target="`baggage-${trip.id}-${fareItem.id}`"
          >
            <div class="text-heading-5 text-white">
              <div v-if="resolveBaggage1GAKComputed.carryBag">
                {{ $t('flight.boardingBaggage') }}: {{ resolveBaggage1GAKComputed.carryBag }}
              </div>
              <div v-if="resolveBaggage1GAKComputed.checkinBag">
                {{ $t('flight.checkinBaggage') }}: {{ resolveBaggage1GAKComputed.checkinBag }}
              </div>
            </div>
          </b-tooltip>
        </b>

        <!-- ANCHOR Hành lý VN1A -->
        <div
          v-if="['VN1A'].includes(trip.source)"
          class="text-dark font-weight-bold d-flex"
        >
          <template v-if="['TAP'].includes(trip.clientId)">
            <LuggageIcon :no-luggage="false" />
            <BLink
              :id="`baggage-${trip.id}-${fareItem.id}`"
              class="text-nowrap d-flex-center"
              @click.stop="handleGotoLinkBaggage"
            >
              <span class="hover-underline">
                Xem hành lý
              </span>
              <feather-icon
                icon="InfoIcon"
                size="14"
                class="text-info ml-25"
              />
            </BLink>
            <!-- <b-tooltip
              triggers="hover"
              variant="info"
              placement="top"
              :target="`baggage-${trip.id}-${fareItem.id}`"
              boundary="window"
            >
              <div class="text-heading-5 text-white">
                <div v-if="resolveBaggageVN1A(fareItem.baggageAllowances).carryBag">
                  {{ $t('flight.boardingBaggage') }}: {{ resolveBaggageVN1A(fareItem.baggageAllowances).carryBag }}
                </div>
                <div v-if="resolveBaggageVN1A(fareItem.baggageAllowances).checkinBag">
                  {{ $t('flight.checkinBaggage') }}: {{ resolveBaggageVN1A(fareItem.baggageAllowances).checkinBag }}
                </div>
              </div>
            </b-tooltip> -->
          </template>

          <template v-else>
            <LuggageIcon :no-luggage="!(fareItem.baggageAllowances && fareItem.baggageAllowances.length && resolveBaggageVN1A(fareItem.baggageAllowances).checkinBag)" />
            <span
              :id="`baggage-${trip.id}-${fareItem.id}`"
              class="text-nowrap d-flex-center"
            >
              <span v-if="fareItem.baggageAllowances && fareItem.baggageAllowances.length">
                {{ resolveBaggageVN1A(fareItem.baggageAllowances).text }}
              </span>
              <feather-icon
                v-if="(fareItem.baggageAllowances && fareItem.baggageAllowances[0] && fareItem.baggageAllowances[0].descriptions && fareItem.baggageAllowances[0].descriptions.length) || !trip.domestic"
                icon="InfoIcon"
                size="14"
                class="text-info ml-25"
              />
            </span>
            <b-tooltip
              triggers="hover"
              variant="info"
              placement="top"
              :target="`baggage-${trip.id}-${fareItem.id}`"
              boundary="window"
            >
              <div class="text-heading-5 text-white">
                <div v-if="resolveBaggageVN1A(fareItem.baggageAllowances).carryBag">
                  {{ $t('flight.boardingBaggage') }}: {{ resolveBaggageVN1A(fareItem.baggageAllowances).carryBag }}
                </div>
                <div v-if="resolveBaggageVN1A(fareItem.baggageAllowances).checkinBag">
                  {{ $t('flight.checkinBaggage') }}: {{ resolveBaggageVN1A(fareItem.baggageAllowances).checkinBag }}
                </div>
                <div v-if="!trip.domestic">
                  {{ $t('reservation.contactBookerBaggage') }}
                </div>
              </div>
            </b-tooltip>
          </template>
        </div>

        <!-- ANCHOR VU Quoc te -->
        <b
          v-if="['VU'].includes(trip.source)"
          class="text-dark font-weight-bold"
        >
          <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('VU', fareItem.fareType).checkinBaggage))" />

          <span :id="`baggage-${trip.id}-${fareItem.id}`">
            {{ fareRulesAirline('VU', fareItem.fareType).boardingBaggage }}
            {{ fareRulesAirline('VU', fareItem.fareType).boardingBaggage &&
              fareRulesAirline('VU', fareItem.fareType).checkinBaggage &&
              fareRulesAirline('VU', fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : '' }}
            <span v-if="fareRulesAirline('VU', fareItem.fareType).checkinBaggage !== 'NONE'">
              {{ fareRulesAirline('VU', fareItem.fareType).checkinBaggage }}
            </span>
          </span>
          <b-tooltip
            v-if="fareRulesAirline('VU', fareItem.fareType).boardingBaggage && fareRulesAirline('VU', fareItem.fareType).checkinBaggage"
            triggers="hover"
            variant="info"
            placement="top"
            boundary="window"
            :target="`baggage-${trip.id}-${fareItem.id}`"
          >
            <div class="text-heading-5 text-white">
              <div>
                {{ $t('flight.boardingBaggage') }}:
                {{ fareRulesAirline('VU', fareItem.fareType).boardingBaggage }}
              </div>
              <div>
                {{ $t('flight.checkinBaggage') }}:
                {{ fareRulesAirline('VU', fareItem.fareType).checkinBaggage }}
              </div>
            </div>
          </b-tooltip>
        </b>

        <!-- ANCHOR: VJ quoc te -->
        <span v-if="['VJ'].includes(trip.source)">
          <!-- NOTE: Nhóm 1:
            - Các đường bay nội địa Việt Nam.
            - Các đường bay thẳng và nối chuyến quốc tế còn lại, ngoại trừ các đường bay Nhóm 2.
            Nhóm 2:
            - Các đường bay quốc tế giữa Kazakhstan/ Úc và Việt Nam.
            - Các đường bay nối chuyến quốc tế từ/ đến Úc. -->
          <span
            v-if="[trip.arrival.IATACode, trip.departure.IATACode].some(item => iataCodeVJ2.includes(item))"
            :id="`baggage-${trip.id}-${fareItem.id}`"
          >
            <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('VJ_2', fareItem.groupClass).checkinBaggage))" />

            {{ fareRulesAirline('VJ_2', fareItem.groupClass).boardingBaggage }}
            {{ fareRulesAirline('VJ_2', fareItem.groupClass).boardingBaggage &&
              fareRulesAirline('VJ_2', fareItem.groupClass).checkinBaggage &&
              fareRulesAirline('VJ_2', fareItem.groupClass).checkinBaggage !== 'NONE' ? ' + ' : ''
            }}
            <span v-if="fareRulesAirline('VJ_2', fareItem.groupClass).checkinBaggage !== 'NONE'">
              {{ fareRulesAirline('VJ_2', fareItem.groupClass).checkinBaggage }}
            </span>
            <b-tooltip
              v-if="fareRulesAirline('VJ_2', fareItem.groupClass).boardingBaggage
                && fareRulesAirline('VJ_2', fareItem.groupClass).checkinBaggage"
              triggers="hover"
              variant="info"
              placement="top"
              boundary="window"
              :target="`baggage-${trip.id}-${fareItem.id}`"
            >
              <div class="text-heading-5 text-white">
                <div>
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline('VJ_2', fareItem.groupClass).boardingBaggage }}
                </div>
                <div>
                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline('VJ_2', fareItem.groupClass).checkinBaggage }}
                </div>
              </div>
            </b-tooltip>
          </span>

          <span
            v-else-if="[trip.arrival.IATACode, trip.departure.IATACode].some(item => iataCodeVJ3.includes(item))"
            :id="`baggage-${trip.id}-${fareItem.id}`"
          >
            <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('VJ_3', fareItem.groupClass).checkinBaggage))" />

            {{ fareRulesAirline('VJ_3', fareItem.groupClass).boardingBaggage }}
            {{ fareRulesAirline('VJ_3', fareItem.groupClass).boardingBaggage &&
              fareRulesAirline('VJ_3', fareItem.groupClass).checkinBaggage &&
              fareRulesAirline('VJ_3', fareItem.groupClass).checkinBaggage !== 'NONE' ? ' + ' : ''
            }}
            <span v-if="fareRulesAirline('VJ_3', fareItem.groupClass).checkinBaggage !== 'NONE'">
              {{ fareRulesAirline('VJ_3', fareItem.groupClass).checkinBaggage }}
            </span>
            <b-tooltip
              v-if="fareRulesAirline('VJ_3', fareItem.groupClass).boardingBaggage
                && fareRulesAirline('VJ_3', fareItem.groupClass).checkinBaggage"
              triggers="hover"
              variant="info"
              placement="top"
              boundary="window"
              :target="`baggage-${trip.id}-${fareItem.id}`"
            >
              <div class="text-heading-5 text-white">
                <div>
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline('VJ_3', fareItem.groupClass).boardingBaggage }}
                </div>
                <div>
                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline('VJ_3', fareItem.groupClass).checkinBaggage }}
                </div>
              </div>
            </b-tooltip>
          </span>

          <!-- NHOM 1 -->
          <span v-else>
            <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('VJ', fareItem.groupClass).checkinBaggage))" />

            {{ fareRulesAirline('VJ', fareItem.groupClass).boardingBaggage }}
            {{ fareRulesAirline('VJ', fareItem.groupClass).boardingBaggage &&
              fareRulesAirline('VJ', fareItem.groupClass).checkinBaggage &&
              fareRulesAirline('VJ', fareItem.groupClass).checkinBaggage !== 'NONE' ? ' + ' : '' }}
            <span
              v-if="fareRulesAirline('VJ', fareItem.groupClass).checkinBaggage !== 'NONE'"
              :id="`baggage-${trip.id}-${fareItem.id}`"
            >
              {{ fareRulesAirline('VJ', fareItem.groupClass).checkinBaggage }}
            </span>
            <b-tooltip
              v-if="fareRulesAirline('VJ', fareItem.groupClass).boardingBaggage
                && fareRulesAirline('VJ', fareItem.groupClass).checkinBaggage"
              triggers="hover"
              variant="info"
              placement="top"
              boundary="window"
              :target="`baggage-${trip.id}-${fareItem.id}`"
            >
              <div class="text-heading-5 text-white">
                <div>
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline('VJ', fareItem.groupClass).boardingBaggage }}
                </div>
                <div>
                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline('VJ', fareItem.groupClass).checkinBaggage }}
                </div>
              </div>
            </b-tooltip>
          </span>
        </span>

        <!-- ANCHOR: QH Quoc te -->
        <span v-if="['QH'].includes(trip.source)">
          <!-- NOTE QH nhóm 1:
            - VN đi Úc và Châu Âu  (MEL, SYD, FRA , LGW)
            - Từ Châu Âu ( trừ Anh ) (FRA)
            - Từ Anh (LGW)
            - Từ ÚC (MEL, SYD)
          -->
          <span
            v-if="[trip.arrival.IATACode, trip.departure.IATACode].some(item => ['MEL', 'SYD', 'FRA', 'LGW'].includes(item))"
            :id="`baggage-${trip.id}-${fareItem.id}`"
          >
            <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('QH_1', fareItem.fareType).checkinBaggage))" />

            {{ fareRulesAirline('QH_1', fareItem.fareType).boardingBaggage }}
            {{ fareRulesAirline('QH_1', fareItem.fareType).boardingBaggage &&
              fareRulesAirline('QH_1', fareItem.fareType).checkinBaggage &&
              fareRulesAirline('QH_1', fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : ''
            }}
            <span v-if="fareRulesAirline('QH_1', fareItem.fareType).checkinBaggage !== 'NONE'">
              {{ fareRulesAirline('QH_1', fareItem.fareType).checkinBaggage }}
            </span>
            <b-tooltip
              v-if="fareRulesAirline('QH_1', fareItem.fareType).boardingBaggage
                && fareRulesAirline('QH_1', fareItem.fareType).checkinBaggage"
              triggers="hover"
              variant="info"
              placement="top"
              boundary="window"
              :target="`baggage-${trip.id}-${fareItem.id}`"
            >
              <div class="text-heading-5 text-white">
                <div>
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline('QH_1', fareItem.fareType).boardingBaggage }}
                </div>
                <div>
                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline('QH_1', fareItem.fareType).checkinBaggage }}
                </div>
              </div>
            </b-tooltip>
          </span>

          <!-- NOTE QH nhóm 2:
                                    - Từ Đài Loan (TPE, KHH)
                                    - Từ Hàn Quốc (ICN)
                                    - Từ Nhật Bản (NRT)
                                  -->
          <span
            v-if="[trip.departure.IATACode].some(item => ['TPE', 'KHH', 'ICN', 'NRT'].includes(item))"
            :id="`baggage-${trip.id}-${fareItem.id}`"
          >
            <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('QH_2', fareItem.fareType).checkinBaggage))" />

            {{ fareRulesAirline('QH_2', fareItem.fareType).boardingBaggage }}
            {{ fareRulesAirline('QH_2', fareItem.fareType).boardingBaggage &&
              fareRulesAirline('QH_2', fareItem.fareType).checkinBaggage &&
              fareRulesAirline('QH_2', fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : ''
            }}
            <span v-if="fareRulesAirline('QH_2', fareItem.fareType).checkinBaggage !== 'NONE'">
              {{ fareRulesAirline('QH_2', fareItem.fareType).checkinBaggage }}
            </span>
            <b-tooltip
              v-if="fareRulesAirline('QH_2', fareItem.fareType).boardingBaggage
                && fareRulesAirline('QH_2', fareItem.fareType).checkinBaggage"
              triggers="hover"
              variant="info"
              placement="top"
              boundary="window"
              :target="`baggage-${trip.id}-${fareItem.id}`"
            >
              <div class="text-heading-5 text-white">
                <div>
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline('QH_2', fareItem.fareType).boardingBaggage }}
                </div>
                <div>
                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline('QH_2', fareItem.fareType).checkinBaggage }}
                </div>
              </div>
            </b-tooltip>
          </span>

          <!-- NOTE QH nhóm 3:
                                    - VN đi Đông Nam Á (SIN , BKK)
                                  -->
          <span
            v-if="[trip.arrival.IATACode].some(item => ['SIN', 'BKK'].includes(item))"
            :id="`baggage-${trip.id}-${fareItem.id}`"
          >
            <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('QH_3', fareItem.fareType).checkinBaggage))" />

            {{ fareRulesAirline('QH_3', fareItem.fareType).boardingBaggage }}
            {{ fareRulesAirline('QH_3', fareItem.fareType).boardingBaggage &&
              fareRulesAirline('QH_3', fareItem.fareType).checkinBaggage &&
              fareRulesAirline('QH_3', fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : ''
            }}
            <span v-if="fareRulesAirline('QH_3', fareItem.fareType).checkinBaggage !== 'NONE'">
              {{ fareRulesAirline('QH_3', fareItem.fareType).checkinBaggage }}
            </span>
            <b-tooltip
              v-if="fareRulesAirline('QH_3', fareItem.fareType).boardingBaggage
                && fareRulesAirline('QH_3', fareItem.fareType).checkinBaggage"
              triggers="hover"
              variant="info"
              placement="top"
              boundary="window"
              :target="`baggage-${trip.id}-${fareItem.id}`"
            >
              <div class="text-heading-5 text-white">
                <div>
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline('QH_3', fareItem.fareType).boardingBaggage }}
                </div>
                <div>
                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline('QH_3', fareItem.fareType).checkinBaggage }}
                </div>
              </div>
            </b-tooltip>
          </span>

          <!-- NOTE QH nhóm 4:
                                    - VN đi Đông Bắc Á (TSN , MFM, LJG , TPE , KHH , NRT , ICN)
                                  -->
          <span
            v-if="[trip.arrival.IATACode].some(item => ['TSN', 'MFM', 'LJG', 'TPE', 'KHH', 'NRT', 'ICN'].includes(item))"
            :id="`baggage-${trip.id}-${fareItem.id}`"
          >
            <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('QH_4', fareItem.fareType).checkinBaggage))" />

            {{ fareRulesAirline('QH_4', fareItem.fareType).boardingBaggage }}
            {{ fareRulesAirline('QH_4', fareItem.fareType).boardingBaggage &&
              fareRulesAirline('QH_4', fareItem.fareType).checkinBaggage &&
              fareRulesAirline('QH_4', fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : ''
            }}
            <span v-if="fareRulesAirline('QH_4', fareItem.fareType).checkinBaggage !== 'NONE'">
              {{ fareRulesAirline('QH_4', fareItem.fareType).checkinBaggage }}
            </span>
            <b-tooltip
              v-if="fareRulesAirline('QH_4', fareItem.fareType).boardingBaggage
                && fareRulesAirline('QH_4', fareItem.fareType).checkinBaggage"
              triggers="hover"
              variant="info"
              placement="top"
              boundary="window"
              :target="`baggage-${trip.id}-${fareItem.id}`"
            >
              <div class="text-heading-5 text-white">
                <div>
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline('QH_4', fareItem.fareType).boardingBaggage }}
                </div>
                <div>
                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline('QH_4', fareItem.fareType).checkinBaggage }}
                </div>
              </div>
            </b-tooltip>
          </span>

          <!-- NOTE QH nhóm 5:
                                    - Từ Singapore(SIN) / Thái Lan (BKK)
                                  -->
          <span
            v-if="[trip.departure.IATACode].some(item => ['SIN', 'BKK'].includes(item))"
            :id="`baggage-${trip.id}-${fareItem.id}`"
          >
            <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('QH_5', fareItem.fareType).checkinBaggage))" />

            {{ fareRulesAirline('QH_5', fareItem.fareType).boardingBaggage }}
            {{ fareRulesAirline('QH_5', fareItem.fareType).boardingBaggage &&
              fareRulesAirline('QH_5', fareItem.fareType).checkinBaggage &&
              fareRulesAirline('QH_5', fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : ''
            }}
            <span v-if="fareRulesAirline('QH_5', fareItem.fareType).checkinBaggage !== 'NONE'">
              {{ fareRulesAirline('QH_5', fareItem.fareType).checkinBaggage }}
            </span>
            <b-tooltip
              v-if="fareRulesAirline('QH_5', fareItem.fareType).boardingBaggage
                && fareRulesAirline('QH_5', fareItem.fareType).checkinBaggage"
              triggers="hover"
              variant="info"
              placement="top"
              boundary="window"
              :target="`baggage-${trip.id}-${fareItem.id}`"
            >
              <div class="text-heading-5 text-white">
                <div>
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline('QH_5', fareItem.fareType).boardingBaggage }}
                </div>
                <div>
                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline('QH_5', fareItem.fareType).checkinBaggage }}
                </div>
              </div>
            </b-tooltip>
          </span>
        </span>
      </div>

      <!-- NOTE: domestic -->
      <div
        v-else
        :id="`baggage-${trip.id}-${fareItem.id}`"
        class="text-truncate d-flex-center"
      >
        <!-- ANCHOR: baggage for QH_VCS (Con Dao) -->
        <b v-if="trip.source === 'QH' && [trip.departure.IATACode, trip.arrival.IATACode].includes('VCS')">
          <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('QH_VCS', fareItem.fareType).checkinBaggage))" />

          <span>{{ fareRulesAirline('QH_VCS', fareItem.fareType).boardingBaggage }}</span>
          {{ fareRulesAirline('QH_VCS', fareItem.fareType).boardingBaggage &&
            fareRulesAirline('QH_VCS', fareItem.fareType).checkinBaggage &&
            fareRulesAirline('QH_VCS', fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : '' }}
          <span>{{ fareRulesAirline('QH_VCS', fareItem.fareType).checkinBaggage }}</span>
          <b-tooltip
            v-if="fareRulesAirline('QH_VCS', fareItem.fareType).boardingBaggage && fareRulesAirline('QH_VCS', fareItem.fareType).checkinBaggage"
            triggers="hover"
            variant="info"
            placement="top"
            boundary="window"
            :target="`baggage-${trip.id}-${fareItem.id}`"
          >
            <div class="text-heading-5 text-white">
              <div>
                {{ $t('flight.boardingBaggage') }}:
                {{ fareRulesAirline('QH_VCS', fareItem.fareType).boardingBaggage }}
              </div>
              <div>
                {{ $t('flight.checkinBaggage') }}:
                {{ fareRulesAirline('QH_VCS', fareItem.fareType).checkinBaggage }}
              </div>
            </div>
          </b-tooltip>
        </b>
        <!-- ANCHOR: else -->
        <b v-else>
          <LuggageIcon
            :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline(trip.segments[0].operating || trip.airline,
                                                                              ['QH', 'VU'].includes(trip.source) ? fareItem.fareType :
                                                                              fareItem.groupClass).checkinBaggage))"
          />

          {{ fareRulesAirline(trip.segments[0].operating || trip.airline,
                              ['QH', 'VU'].includes(trip.source) ? fareItem.fareType :
                              fareItem.groupClass).boardingBaggage }}
          {{ fareRulesAirline(trip.segments[0].operating || trip.airline,
                              ['QH', 'VU'].includes(trip.source) ? fareItem.fareType :
                              fareItem.groupClass).boardingBaggage &&
            fareRulesAirline(trip.segments[0].operating || trip.airline,
                             ['QH', 'VU'].includes(trip.source) ? fareItem.fareType :
                             fareItem.groupClass).checkinBaggage &&
            fareRulesAirline(trip.segments[0].operating || trip.airline,
                             ['QH', 'VU'].includes(trip.source) ? fareItem.fareType :
                             fareItem.groupClass).checkinBaggage !== 'NONE' ? ' + ' : '' }}
          <span v-if="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).checkinBaggage !== 'NONE'">
            {{
              trip.source === 'QH' && fareItem.bookingClass === 'A' && fareItem.fareType === 'EconomySaver' && moment(trip.departure.at).isBetween('2024-01-24', '2024-02-26')
                ? '30KG'
                : (fareRulesAirline(trip.segments[0].operating || trip.airline,
                                    ['QH', 'VU'].includes(trip.source) ? fareItem.fareType :
                                    fareItem.groupClass).checkinBaggage) }}
          </span>
          <b-tooltip
            v-if="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).boardingBaggage
              && fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).checkinBaggage"
            triggers="hover"
            variant="info"
            placement="top"
            boundary="window"
            :target="`baggage-${trip.id}-${fareItem.id}`"
          >
            <div class="text-heading-5 text-white">
              <div>
                {{ $t('flight.boardingBaggage') }}:
                {{ fareRulesAirline(trip.segments[0].operating || trip.airline,
                                    ['QH', 'VU'].includes(trip.source) ? fareItem.fareType :
                                    fareItem.groupClass).boardingBaggage }}
              </div>
              <div>
                {{ $t('flight.checkinBaggage') }}:
                {{ trip.source === 'QH' && fareItem.bookingClass === 'A' && fareItem.fareType === 'EconomySaver' && moment(trip.departure.at).isBetween('2024-01-24', '2024-02-26')
                  ? '30KG': fareRulesAirline(trip.segments[0].operating || trip.airline,
                                             ['QH', 'VU'].includes(trip.source) ? fareItem.fareType :
                                             fareItem.groupClass).checkinBaggage }}
              </div>
            </div>
          </b-tooltip>
        </b>
      </div>
    </div>
    <!-- !SECTION - END Điều kiện hành lý -->

    <!-- SECTION Hoàn vé -->
    <div class="d-flex-center">
      <!-- NOTE: Hoàn vé '1G', 'AK' -->
      <div v-if="['1G', 'AK'].includes(trip.source)">
        <div
          v-if="fareItem.cancelPenalties && fareItem.cancelPenalties.length && !fareItem.cancelPenalties[0].nonRefundable"
          v-b-tooltip.hover="{ variant: 'success' }"
          v-b-tooltip.hover.html="`Phí vui lòng liên hệ booker`"
          class="d-flex-center"
        >
          <!-- v-b-tooltip.hover.html="`${mappingPenalties(fareItem.cancelPenalties)}`" -->
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/can-refund.svg`)"
          />
          <span>
            {{ $t('flight.fareRules.refundable') }}
          </span>
        </div>

        <div
          v-else
          v-b-tooltip.window.hover="{ variant: 'danger' }"
          class="d-flex-center"
          :title="`${$t(`flight.fareRuleTimeStatus.noRefundable`)}`"
        >
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/no-refund.svg`)"
          />
          <span class="text-danger">
            {{ $t('flight.fareRules.noRefundable') }}
          </span>
        </div>
      </div>

      <!-- NOTE: Hoàn vé 1B -->
      <div v-else-if="['1B', '1A'].includes(trip.source)">
        <div
          v-if="fareItem.refundPenalties && fareItem.refundPenalties.length && !fareItem.refundPenalties[0].nonRefundable"
          v-b-tooltip.hover="{ variant: 'success' }"
          v-b-tooltip.hover.window.html="`Phí vui lòng liên hệ booker`"
          class="d-flex-center"
        >
          <!-- v-b-tooltip.hover.html="`${mappingPenalties(fareItem.cancelPenalties)}`" -->
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/can-refund.svg`)"
          />
          <span>
            {{ $t('flight.fareRules.refundable') }}
          </span>
        </div>

        <div
          v-else
          v-b-tooltip.hover="{ variant: 'danger' }"
          class="d-flex-center"
          :title="`${$t(`flight.fareRuleTimeStatus.noRefundable`)}`"
        >
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/no-refund.svg`)"
          />
          <span class="text-danger">
            {{ $t('flight.fareRules.noRefundable') }}
          </span>
        </div>
      </div>

      <!-- NOTE: Hoàn vé VU QTE -->
      <div v-else-if="['VU'].includes(trip.source)">
        <div
          v-if="fareRulesAirline(trip.segments[0].operating || trip.airline, fareItem.fareType)
            ? !['', 'NONE', false].includes(fareRulesAirline(trip.segments[0].operating || trip.airline, fareItem.fareType).refund)
            : !fareItem.noRefund"
          v-b-tooltip.hover="{ variant: 'success' }"
          class="d-flex-center"
          :title="fareRulesAirline(trip.segments[0].operating || trip.airline, fareItem.fareType)
            ? fareRulesAirline(trip.segments[0].operating || trip.airline, fareItem.fareType).refund
            : $t('flight.fareRuleTimeStatus.refundable')"
        >
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/can-refund.svg`)"
          />
          <span>
            {{ $t('flight.fareRules.refundable') }}
          </span>
        </div>

        <div
          v-else
          v-b-tooltip.hover.v-danger
          icon="TrelloIcon"
          class="d-flex-center"
          badge-classes="badge-info badge-glow"
          :title="$t(`flight.fareRuleTimeStatus.noRefundable`)"
        >
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/no-refund.svg`)"
          />
          <span class="text-danger">
            {{ $t('flight.fareRules.noRefundable') }}
          </span>
        </div>
      </div>

      <!-- Hoàn vé nội địa -->
      <div v-else-if="trip.domestic">
        <div
          v-if="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass)
            ? !['', 'NONE', false].includes(fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).refund)
            : !fareItem.noRefund"
          v-b-tooltip.hover="{ variant: 'success' }"
          class="d-flex-center"
          :title="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass)
            ? fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).refund
            : $t('flight.fareRuleTimeStatus.refundable')"
        >
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/can-refund.svg`)"
          />
          <span>
            {{ trip.source !== 'VJ'? $t('flight.fareRules.refundable') : $t('flight.fareRules.refundableIdentify') }}
          </span>
        </div>

        <div
          v-else
          v-b-tooltip.hover.v-danger
          icon="TrelloIcon"
          class="d-flex-center"
          badge-classes="badge-info badge-glow"
          :title="$t(`flight.fareRuleTimeStatus.noRefundable`)"
        >
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/no-refund.svg`)"
          />
          <span class="text-danger">
            {{ $t('flight.fareRules.noRefundable') }}
          </span>
        </div>
      </div>
    </div>
    <!-- !SECTION END Hoàn vé -->

    <!-- SECTION Đổi ngày bay -->
    <div class="d-flex-center">
      <div v-if="['1G', 'AK', '1B', '1A'].includes(trip.source)">
        <div
          v-if="fareItem.changePenalties && fareItem.changePenalties.length && !fareItem.changePenalties[0].nonRefundable"
          v-b-tooltip.hover="{ variant: 'success' }"
          v-b-tooltip.hover.html="`${$t('flight.fareRules.contactBooker')}`"
          class="d-flex-center"
        >
          <!-- v-b-tooltip.hover.html="`${mappingPenalties(fareItem.changePenalties)}`" -->
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/can-change.svg`)"
          />
          <span>
            {{ $t('flight.fareRules.reschedulable') }}
          </span>
        </div>

        <div
          v-else
          v-b-tooltip.hover="{ variant: 'danger' }"
          class="d-flex-center"
          :title="$t(`flight.fareRuleTimeStatus.noReschedulable`)"
        >
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/no-change.svg`)"
          />
          <span class="text-danger">
            {{ $t('flight.fareRules.noReschedulable') }}
          </span>
        </div>
      </div>

      <!-- Đổi ngày bay nội địa -->
      <div v-else-if="trip.domestic">
        <div
          v-if="!['NONE', false, ''].includes(fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).reschedule)"
          v-b-tooltip.hover="{ variant: 'success' }"
          badge-classes="badge-info badge-glow"
          class="d-flex-center"
          :title="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).reschedule"
        >
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/can-change.svg`)"
          />
          <span>
            {{ $t('flight.fareRules.reschedulable') }}
          </span>
        </div>

        <div
          v-else
          v-b-tooltip.hover.v-danger
          badge-classes="badge-info badge-glow"
          class="d-flex-center"
          :title="$t(`flight.fareRuleTimeStatus.noReschedulable`)"
        >
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/no-change.svg`)"
          />
          <span class="text-danger">
            {{ $t('flight.fareRules.noReschedulable') }}
          </span>
        </div>
      </div>
    </div>
    <!-- !SECTION END Đổi ngày bay -->

    <!-- SECTION Noshow -->
    <div class="d-flex-center">
      <div v-if="trip.source === '1G'">
        <div
          v-if="fareItem.noshowPenalties && fareItem.noshowPenalties.length && !fareItem.noshowPenalties[0].nonRefundable"
          v-b-tooltip.hover="{ variant: 'success' }"
          v-b-tooltip.hover.html="`${$t('flight.fareRules.contactBooker')}`"
          class="d-flex-center"
        >
          <!-- v-b-tooltip.hover.html="`${mappingPenalties(fareItem.noshowPenalties)}`" -->
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/can-change.svg`)"
          />
          <span>
            {{ $t(`flight.fareRuleTimeStatus.noshow`) }}
          </span>
        </div>

        <div
          v-else
          v-b-tooltip.hover="{ variant: 'danger' }"
          class="d-flex-center"
          :title="$t(`flight.fareRuleTimeStatus.notNoshow`)"
        >
          <b-img
            class="mr-25"
            blank-color="#ccc"
            style="height: 24px;"
            :src="require(`@/assets/other-icon/no-change.svg`)"
          />
          <span class="text-danger">
            {{ $t(`flight.fareRuleTimeStatus.notNoshow`) }}
          </span>
        </div>
      </div>
    </div>
    <!-- !SECTION END Noshow -->

    <!-- SECTION - BUTTON - Điều kiện vé -->
    <div class="d-flex-center justify-content-end flex-1 mr-0">
      <b-button
        v-if="['VN1A', '1G', 'VU', 'QH', 'VJ', 'AK', 'TH', '1A'].includes(trip.source) || (trip.source === 'F1' && airlineHaveLinkFareRule.includes(trip.airline))"
        variant="outline-info"
        class="px-75 py-50 rounded-lg text-nowrap"
        @click.prevent.stop="onFareRuleHandle(trip, fareItem)"
      >
        {{ $t('flight.fareRule') }}
      </b-button>
      <ModalFareRules
        :id="`${fareItem.id}-${trip.id}`"
        :trip="trip"
        :fare-option="fareItem"
        :fare-value="trip?.fareValue"
      />
    </div>
  </div>
</template>
<script>
import {
  BImg, BButton, BTooltip, VBTooltip, BLink,
} from 'bootstrap-vue'
import { computed, ref, toRefs } from '@vue/composition-api'

import store from '@/store'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BImg,
    BButton,
    BTooltip,
    BLink,
    ModalFareRules: () => import('./ModalFareRules.vue'),
    LuggageIcon: () => import('./LuggageIcon.vue'),
  },
  directives: { 'b-tooltip': VBTooltip },
  props: {
    fareItem: {
      type: Object,
      default: () => {},
    },
    trip: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const {
      resolveBaggage1G,
      resolveBaggageVN1A,
      mappingPenalties,
      iataCodeVJ2,
      iataCodeVJ3,
    } = useBookingHandle()
    const { fareItem, trip } = toRefs(props)
    function fareRulesAirline(airline, cabin) {
      return store.getters['globalConfig/getFareRulesAirline'](airline, cabin)
    }

    const resolveBaggage1GAKComputed = computed(() => {
      if (['AK', '1G', 'TH', '1A', '1B'].includes(trip.value.source)) return resolveBaggage1G(fareItem.value.baggageAllowances, trip.value.source)
      return {}
    })

    function onFareRuleHandle(trip, fareItem) {
      this.$bvModal.show(`modal-fare-rules-${fareItem.id}-${trip.id}`)
    }

    const handleGotoLinkBaggage = () => {
      window.open('https://www.vietnamairlines.com/vn/vi/buy-tickets-other-products/fare-conditions/fare-types', '_blank')
    }

    const airlineHaveLinkFareRule = ref(['AK', '5J', 'TW', 'DD', 'SG', 'JQ', 'TR'])

    return {
      fareRulesAirline,
      onFareRuleHandle,
      resolveBaggage1GAKComputed,
      resolveBaggageVN1A,
      mappingPenalties,
      iataCodeVJ2,
      iataCodeVJ3,
      handleGotoLinkBaggage,
      airlineHaveLinkFareRule,
    }
  },
}
</script>
<style lang="">

</style>
